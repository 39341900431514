<template>
  <PageTemplate>
		<Section>
			<Stack>
				<Images height="500px" width="25%" image="theme-sd/home1.jpg" />
				<Images height="500px" width="25%" image="theme-sd/services4.jpg" />
				<Images height="500px" width="25%" image="theme-sd/services1.jpg" />
				<Images height="500px" width="25%" image="theme-sd/home4.jpg" />
			</Stack>
			<Card size="large">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="1" align="center">let's chat</Heading>
						<Heading size="4" align="center" color="tertiary">WE CAN'T WAIT TO HEAR FROM YOU!</Heading>
							<Paragraph align="center">
								<Link url="/sedona/contact" text="hello@email.com" display="inline" /> |  123.456.7890  |  1234 Road St. Las Vegas, Nevada
							</Paragraph>
						<Grid>
							<GridContainer size="1"></GridContainer>
							<GridContainer size="10">
								<Form>
									<FormContainer size="6">
										<Input label="First Name" />
									</FormContainer>
									<FormContainer size="6">
										<Input label="Last Name" />
									</FormContainer>
									<FormContainer size="12">
										<Input label="Email" />
									</FormContainer>
									<FormContainer size="12">
										<Input label="Subject" />
									</FormContainer>
									<FormContainer size="12">
										<Input label="Message" />
									</FormContainer>
								</Form>
							</GridContainer>
							<GridContainer size="1"></GridContainer>
						</Grid>

						<Button label="Submit" color="primary" size="large" />

					</Stack>
				</CardSection>
			</Card>
			
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/sedona/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
